<template>
  <div>
    <div class="card-toolbar mb-5">
      <div class="d-flex">
        <button v-if="$can('cash_movement.add_cash_in')" class="btn btn-success font-weight-bolder mr-1 ml-1"
                @click="showModal(1)">
          {{ $t('pos_session.add_cash_in') }}
        </button>
        <button v-if="$can('cash_movement.add_cash_out')" class="btn btn-danger font-weight-bolder mr-1 ml-1"
                @click="showModal(2)">
          {{ $t('pos_session.take_cash_out') }}
        </button>
      </div>
    </div>
    <!--begin::User-->
    <div class="card card-custom">
      <!--            <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--                <div class="card-title">-->
      <!--                    <h3 class="card-label">{{ $t('MENU.cash_movement') }}-->
      <!--                        <span class="text-muted pt-2 font-size-sm d-block">{{ $t('pos_session.cash_movement_section') }}</span></h3>-->
      <!--                </div>-->
      <!--            </div>-->
      <div class="card-body">

        <!--                <div class="mt-5" v-if="$can('cash_movement.create')">-->
        <!--                    <div class="d-flex justify-content-end">-->
        <!--                        <button @click="showModal(1)" class="btn btn-success font-weight-bolder mr-1 ml-1">-->
        <!--                            {{ $t('pos_session.add_cash_in') }}-->
        <!--                        </button>-->
        <!--                        <button @click="showModal(2)" class="btn btn-danger font-weight-bolder mr-1 ml-1">-->
        <!--                            {{ $t('pos_session.take_cash_out') }}-->
        <!--                        </button>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="mt-5">
          <v-server-table ref="tableCashMovement" :class="'dataTable table-row-dashed'" :columns="columns2"
                          :options="options2">

            <template slot="date_time" slot-scope="props">
              <p>{{ props.row.id }} - {{ props.row.transaction_date }}</p>

              <div class="d-flex justify-content-center">
                <p>
                  <i v-if="props.row.payment_type == 1" class="far fa-arrow-alt-circle-down text-success"
                     style="font-size: 35px;"></i>
                  <i v-else-if="props.row.payment_type == 2" class="far fa-arrow-alt-circle-up text-danger"
                     style="font-size: 35px;"></i>
                </p>
                <p class="ml-2 mr-2 pt-2">{{ props.row.added_by }}</p>
              </div>
            </template>
            <template slot="amount" slot-scope="props">
              <p>{{ props.row.cash_movement_amount }} {{ props.row.currency_name }}</p>
              <p>{{ props.row.staff_name }}</p>
            </template>
            <template slot="actions" slot-scope="props">
              <v-icon v-if="$can('cash_movement.update')" v-b-tooltip.hover :title="$t('edit')" class="mr-2 ml-2 text-info"
                      small @click="editItem(props.row)">mdi-pencil
              </v-icon>
              <v-icon v-if="$can('cash_movement.delete')" v-b-tooltip.hover :title="$t('delete')" class="text-danger"
                      small @click="deleteItem(props.row)">mdi-delete
              </v-icon>

              <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                <router-link v-if="$can('cash_movement.printing_export_pdf')" :to="`/print/cash-movement/${props.row.id}/print`" class="dropdown-item"
                             target="_blank">
                  {{ $t('print') }}
                </router-link>
                <router-link v-if="$can('cash_movement.printing_export_pdf')" :to="`/print/cash-movement/${props.row.id}/pdf`" class="dropdown-item"
                             target="_blank">
                  {{ $t('export_pdf') }}
                </router-link>


              </b-dropdown>
            </template>
          </v-server-table>
        </div>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
    <b-modal id="cashMovementModal" ref="cashMovementModal" :title="$t('pos_session.cash_movement')" hide-footer
             size="lg">
      <pos-session-cash-movement-form :current-id="inner_cash_movement_id"
                                      :current-session-id="id"
                                      :payment-type="payment_type"
                                      @hide-modal="hideModal()"
                                      @handling-data="getDataAfterCashMovement">
      </pos-session-cash-movement-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import PosSessionCashMovementForm from "@/view/content/forms/PosSessionCashMovementForm";

export default {
  name: "index-cash-movement",
  components: {'pos-session-cash-movement-form': PosSessionCashMovementForm},
  data() {
    return {
      mainRoute: 'pos/cash-movement',

      dataList: [],
      id: this.$route.params.id ? this.$route.params.id : null,
      filters: {
        session_id: this.$route.params.id ? this.$route.params.id : null,
      },
      columns2: ['date_time', 'amount', 'actions'],
      payment_type: null,
      inner_cash_movement_id: null,
    }
  },
  computed: {
    options2: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          date_time: that.$t('pos_session.date_time'),
          amount: that.$t('pos_session.amount'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'id'},
        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});
        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          that.cash_movement_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.pos_sessions"),
      route: '/pos/pos-session'
    }, {title: this.$t("MENU.cash_movement")}]);

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'cashMovementModal') {
        this.payment_type = null;
        this.inner_cash_movement_id = null;
      }
    });
  },
  methods: {
    showModal(payment_type = null) {
      this.payment_type = payment_type;
      this.$refs['cashMovementModal'].show()
    },
    hideModal() {
      this.payment_type = null;
      this.inner_cash_movement_id = null;
      this.$refs['cashMovementModal'].hide();
    },
    getDataAfterCashMovement() {
      this.payment_type = null;
      this.inner_cash_movement_id = null;
      this.getFetchCashMovement();
    },
    getFetchCashMovement() {
      this.$refs.tableCashMovement.refresh();
    },
    editItem(item) {
      this.inner_cash_movement_id = item.id;
      this.showModal();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetchCashMovement();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
  },
};
</script>
