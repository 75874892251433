<template>
  <div>
    <!--begin::customer-->
    <div class="form-group row">
      <div v-if="!isEditing" class="col-lg-6 mb-5">
        <label>{{ $t('pos_session.from_type') }}<span class="text-danger">*</span></label>
        <select v-model="data.type_type" :class="validation && validation.type_type ? 'is-invalid' : ''"
                class="custom-select">
          <option v-for="row in received_types" :key="row.id" :value="row.id">{{ row.title }}</option>
        </select>
        <span v-if="validation && validation.type_type" class="fv-plugins-message-container invalid-feedback">
          {{ validation.type_type[0] }}
        </span>
      </div>
      <div v-if="(data.type_type == 1 || data.type_type == 2) && !isEditing" class="col-lg-6 mb-5">
        <template v-if="data.type_type == 1">
          <label>{{ $t('pos_session.staff') }}<span class="text-danger">*</span></label>
          <!--                    <select v-model="data.type_id" class="custom-select" :class="validation && validation.type_id ? 'is-invalid' : ''">-->
          <!--                        <option v-for="row in employees" :value="row.id" :key="row.id">{{row.full_name}}</option>-->
          <!--                    </select>-->
          <multiselect v-model="type"
                       :class="validation && validation.type_id ? 'is-invalid' : ''"
                       :multiple="false"
                       :options="employees"
                       :placeholder="$t('pos_session.staff')"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       :taggable="false"
                       label="full_name"
                       track-by="id">
          </multiselect>
          <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
            {{ validation.type_id[0] }}
          </span>
        </template>

        <template v-if="data.type_type == 2">
          <label>{{ $t('pos_session.treasury') }}<span class="text-danger">*</span></label>
          <multiselect v-model="type"
                       :multiple="false"
                       :options="treasuries"
                       :placeholder="$t('pos_session.treasury')"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       :taggable="false"
                       label="name"
                       track-by="id">
          </multiselect>
        </template>
        <span v-if="validation && validation.type_type" class="fv-plugins-message-container invalid-feedback">
          {{ validation.type_type[0] }}
        </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('pos_session.amount') }}<span class="text-danger">*</span></label>
        <input v-model="data.cash_movement_amount"
               :class="validation && validation.cash_movement_amount ? 'is-invalid' : ''" class="form-control"
               type="number">
        <span v-if="validation && validation.cash_movement_amount"
              class="fv-plugins-message-container invalid-feedback">
          {{ validation.cash_movement_amount[0] }}
        </span>
      </div>


      <div class="col-lg-6 mb-5">
        <label>{{ $t('pos_session.currency') }}<span class="text-danger">*</span></label>
        <select id="currency_id" v-model="data.currency_id"
                :class="validation && validation.currency_id ? 'is-invalid' : ''" class="custom-select"
                name="">
          <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
        </select>
        <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
          {{ validation.currency_id[0] }}
        </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('exchange_rate') }}</label>
        <input v-model="data.exchange_rate" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"
               class="form-control" min="0" step="0.01"
               type="number"/>
        <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
          {{ validation.exchange_rate[0] }}
        </span>
      </div>
      <div v-if="!isEditing" class="col-lg-6 mb-5">
        <label>{{ $t('pos_session.date_time') }}<span class="text-danger">*</span></label>
        <input v-model="data.transaction_date" :class="validation && validation.transaction_date ? 'is-invalid' : ''"
               class="form-control"
               type="datetime-local">
        <span v-if="validation && validation.transaction_date" class="fv-plugins-message-container invalid-feedback">
          {{ validation.transaction_date[0] }}
        </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('pos_session.notes') }}</label>
        <textarea id="notes" v-model="data.notes" :class="validation && validation.notes ? 'is-invalid' : ''"
                  class="form-control"
                  name=""></textarea>
        <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
          {{ validation.notes[0] }}
        </span>
      </div>
    </div>
    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button class="btn btn-primary mr-2" type="reset" @click="save">{{ $t('save') }}</button>
          <button class="btn btn-secondary" type="reset" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>

    <!--end::customer-->
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "form-pos-session-cash-movement",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    currentId: null,
    currentSessionId: null,
    paymentType: null,

  },
  data() {
    return {
      mainRoute: 'pos/cash-movement',
      mainRouteDependency: 'base/dependency',

      received_types: [],
      employees: [],
      treasuries: [],
      currencies: [],

      data: {
        session_pos_id: null,
        cash_movement_amount: null,
        currency_id: null,
        transaction_date: null,
        notes: null,
        type_type: null,
        type_id: null,
        payment_type: null,
        exchange_rate: null,
      },
      filters: {
        session_id: this.$route.params.id ? this.$route.params.id : null,
        is_cash_movement: 1,

      },
      validation: null,
      difference: null,
      type: null,
      isEditing: false,

    };
  },
  watch: {
    type: function (val) {
      if (val) {
        this.data.type_id = val.id;
      } else {
        this.data.type_id = null;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  methods: {
    save() {
      if (this.currentId) {
        this.update();
      } else {
        this.create();
      }
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
    },
    finishedSave() {
      this.$emit('handling-data');
    },
    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.cancel();
      }).catch(error => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      ApiService.put(this.mainRoute + '/' + this.currentId, {
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.cancel();
      }).catch(error => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      this.isEditing = true;
      ApiService.get(this.mainRoute + '/' + this.currentId).then((response) => {
        // this.type = response.data.data.type;
        this.data.session_pos_id = response.data.data.session_pos_id;
        this.data.cash_movement_amount = response.data.data.cash_movement_amount;
        this.data.currency_id = response.data.data.currency_id;
        this.data.transaction_date = response.data.data.transaction_date;
        this.data.notes = response.data.data.notes;
        this.data.type_type = response.data.data.type_type_id;
        this.data.type_id = response.data.data.type_id;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.payment_type = response.data.data.payment_type;

      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getReceivedType() {
      ApiService.get(this.mainRouteDependency + "/received_type").then((response) => {
        this.received_types = response.data.data;
      });
    },
    getEmployees() {
      ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
        this.employees = response.data.data;
      });
    },
    getTreasuries() {
      // let params = this.filters
      // loop in filters object and convert it to params
      let params = Object.keys(this.filters).map((key) => {
        return key + '=' + this.filters[key];
      }).join('&');
      ApiService.get(this.mainRouteDependency + "/treasuries?" + params).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },
    resetAfterSave() {
      this.data.session_pos_id = null;
      this.data.cash_movement_amount = null;
      this.data.currency_id = null;
      this.data.transaction_date = null;
      this.data.notes = null;
      this.data.type_type = null;
      this.data.type_id = null;
      this.isEditing = false;
      this.type = null;
    },

  },

  mounted() {
    this.data.session_pos_id = this.currentSessionId;
    this.data.payment_type = this.paymentType;
    this.data.transaction_date = this.$moment().format('YYYY-MM-DDThh:mm');
    if (this.currentId) {
      this.getData();
    } else {
      this.isEditing = false;
      this.getDefaultCurrency();
    }
    this.getCurrencies();
    this.getReceivedType();
    this.getEmployees();
    this.getTreasuries();
  },
};
</script>
